import {useParams} from "react-router";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import {Button, Heading} from "@radix-ui/themes";
import {Link} from "react-router-dom";
import {get} from "lodash";
import React from "react";
import {ArrowDownRightIcon, EyeIcon} from "@heroicons/react/16/solid";
import classNames from "classnames";
import AnchorButton from "~/components/AnchorButton";
import { BadgeDollarSignIcon, CheckCircle, CircleDollarSignIcon, MousePointerClickIcon, ShoppingCartIcon, TestTubeIcon, ViewIcon } from "lucide-react";
import { PlusIcon, ShoppingCartIcon } from "lucide-react";
import { Badge, Button } from "@radix-ui/themes";
import { ArrowUpRightIcon, ArrowDownRightIcon, EyeIcon, EditIcon } from "lucide-react";
import { MousePointerClickIcon, ShoppingCartIcon, CircleDollarSignIcon } from "lucide-react";

export default function WorkflowPaywallsPage() {

  const params = useParams();

  const {data: workflow, error} = useSWR(
    `/convert/workflows/${params.id}`,
    url => apiClient.get(url).then(res => res.data)
  );

  if (!workflow) return;

  return (
    <WorkflowLayout workflow={workflow}>
      <div className="px-10">
        <PaywallList
          workflow={workflow}
        />

      </div>
    </WorkflowLayout>
  )
}



function EmptyState({ title, description, actionText, onAction }) {
  return (
    <div className="text-center py-16 bg-gray-50 rounded-lg">
      <ShoppingCartIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-4 text-lg font-semibold text-gray-900">{title}</h3>
      <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
        {description}
      </p>
      {actionText && (
        <div className="mt-6">
          <Button size="2" onClick={onAction}>
            <PlusIcon className="h-4 w-4 mr-2" />
            {actionText}
          </Button>
        </div>
      )}
    </div>
  );
}

function PaywallList({ workflow }) {
  const {
    data: collection,
    error
  } = useSWR(`/convert/workflows/${workflow.id}/paywalls`, getFetcher);

  if (error) return <div>Failed to load</div>;
  if (!collection) return <div>Loading...</div>;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center pt-2">
        <Heading as="h1" size="6">Paywall Variants</Heading>
        <Button size="2" variant="outline" asChild>
          <Link to={`/convert/workflows/${workflow.id}/paywalls/create`}>
            <PlusIcon className="h-4 w-4 mr-2" />
            Create New Paywall
          </Link>
        </Button>
      </div>

      {collection.data.length === 0 ? (
        <EmptyState
          title="No Paywalls Yet"
          description="Create your first paywall to start monetizing your workflow. Paywalls help you control access to premium content or features."
          actionText="Create Your First Paywall"
          onAction={() => {
            // Navigate to paywall creation page
            window.location.href = `/convert/workflows/${workflow.id}/paywalls/create`;
          }}
        />
      ) : (
        <div className="space-y-4">
          {collection.data.map(paywall => (
            <PaywallStatsComponent
              key={paywall.id}
              paywall={paywall}
              analytics={get(collection, `analytics.${paywall.id}`, {
                count_entered: 0,
                count_started: 0,
                count_completed: 0,
                diff_entered: 0,
                diff_entered_percentage: 0,
                diff_started: 0,
                diff_started_percentage: 0,
                diff_completed: 0,
                diff_completed_percentage: 0,
                total_revenue: null,
              })}
              workflow={workflow}
              isWinner={paywall.id === collection.winner}
            />
          ))}
        </div>
      )}
    </div>
  );
}



function PaywallStatsComponent({ analytics, paywall, isWinner, workflow }) {



  return (
    <div className="bg-gray-50 rounded-lg border border-gray-200/50 overflow-hidden ">
      <div className="grid grid-cols-12 ">
        
        <div className="px-5 py-4 space-y-2 col-span-4 relative flex flex-col justify-between">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{paywall.name}</h3>
            <p className="text-gray-900/50 font-semibold text-xs truncate">{paywall.id}</p>
          </div>
          <div className="flex gap-4">
            <Button size="2" variant="ghost" asChild>
              <Link to={`/convert/paywalls/${paywall.id}/view`}>
                <ViewIcon className="w-3 h-3 mr-1" /> View
              </Link>
            </Button>

            <Button size="2" variant="ghost" asChild>
              <Link to={`/convert/paywalls/${paywall.id}/edit`}>
                <EditIcon className="w-3 h-3 mr-1" /> Edit
              </Link>
            </Button>
            <Button size="2" variant="ghost" asChild>
              <Link to={`/convert/paywalls/${paywall.id}/sandbox`}>
                <TestTubeIcon className="w-3 h-3 mr-1" /> Sandbox
              </Link>
            </Button>
          </div>
        </div>


        <StatColumn
          // icon={<MousePointerClickIcon className="w-3 h-3 flex-shrink-0 " />}
          title="Unique Views"
          value={analytics.count_entered}
          change={analytics.diff_entered}
          percentage={analytics.diff_entered_percentage}
        />

        <StatColumn
          // icon={<ShoppingCartIcon className="w-3 h-3 flex-shrink-0 " />}
          title="Checkout Started"
          value={analytics.count_started}
          change={analytics.diff_started}
          percentage={analytics.diff_started_percentage}
        />

        <StatColumn
          // icon={<CheckCircle className="w-3 h-3 flex-shrink-0 " />}
          title="Checkout Complete"
          value={analytics.count_completed}
          change={analytics.diff_completed}
          percentage={analytics.diff_completed_percentage}
        />

        <StatColumn
          // icon={<CircleDollarSignIcon className="w-3 h-3 flex-shrink-0 " />}
          title="Total Revenue"
          value={analytics.total_revenue}
          change={null}
          percentage={null}
        />
      </div>
    </div>
  );
}

function StatColumn({ icon, title, value, change, percentage }) {
  const isPositive = percentage > 0;
  const isNegative = percentage < 0;

  return (
    <div className="py-4 space-y-2 col-span-2 flex flex-col justify-between text-center">
      <div>
        <dt className="text-sm font-medium text-gray-800 flex items-center justify-center gap-2 text-sm">
          {icon}
          {title}
        </dt>
        <dd className="mt-1 text-4xl font-semibold text-gray-900">{value}</dd>
      </div>
      {percentage === null && <div className="text-gray-700 text-xs">All Time</div>}
      {percentage !== null && (
        <div className="flex items-center text-xs gap-2 justify-center">
        {percentage !== 0 && (
          <div
            className={`flex items-center ${
              isPositive ? 'text-green-600' : isNegative ? 'text-red-600' : 'text-gray-600'
            }`}
          >
            {isPositive && <ArrowUpRightIcon className="w-3 h-3 mr-1" />}
            {isNegative && <ArrowDownRightIcon className="w-3 h-3 mr-1" />}
            {percentage !== 0 ? `${Math.abs(percentage)}%` : ''}
          </div>
        )}

        <div className="text-gray-700">
          {change > 0 ? '+' : ''}{change} this week
        </div>
      </div>
      )}
      
    </div>
  );
}
