import { useParams, useSearchParams } from "react-router-dom";
import WorkflowLayout from "./WorkflowLayout";
import useSWR from "swr";
import apiClient from "~/lib/apiClient";
import { Heading, Table } from "@radix-ui/themes";
import { format } from "date-fns";
import Pagination from "~/components/Pagination"; // Adjust the import path as needed

export default function WorkflowRevenuePage() {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || "1", 10);

	const { data: workflow, error: workflowError } = useSWR(
		`/convert/workflows/${params.id}`,
		url => apiClient.get(url).then(res => res.data)
	);

	const { data: revenueData, error: revenueError } = useSWR(
		`/convert/workflows/${params.id}/conversions?page=${page}`,
		url => apiClient.get(url).then(res => res.data)
	);

	if (!workflow || !revenueData) return null;

	const handlePageChange = (newPage: number) => {
		setSearchParams({ page: newPage.toString() });
	};

	return (
		<WorkflowLayout workflow={workflow}>
			<div className="px-10 space-y-4">
				<Table.Root variant="surface">
					<Table.Header>
						<Table.Row>
							<Table.ColumnHeaderCell>Customer Email</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Placement</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Item Name</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Completed At</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Excluded</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{revenueData.data.map((conversion) => (
							<Table.Row key={conversion.id}>
								<Table.Cell>{conversion.customer_email}</Table.Cell>
								<Table.Cell>{conversion.placement}</Table.Cell>
								<Table.Cell>{conversion.variant_name}</Table.Cell>
								<Table.Cell>{conversion.items[0]?.name || 'N/A'}</Table.Cell>
								<Table.Cell>{conversion.items[0]?.amount_total_formatted || 'N/A'}</Table.Cell>
								<Table.Cell>{format(new Date(conversion.completed_at * 1000), 'yyyy-MM-dd HH:mm:ss')}</Table.Cell>
								<Table.Cell>{conversion.is_excluded ? 'Yes' : 'No'}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
				<Pagination
					currentPage={revenueData.current_page}
					totalPages={Math.ceil(revenueData.total / revenueData.per_page)}
					onPageChange={handlePageChange}
				/>
			</div>
		</WorkflowLayout>
	);
}