import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useMemo, useState} from "react";

import {useNavigate} from "react-router-dom";
import JSONEditor from "~/components/JSONEditor";
import {Form} from "@radix-ui/react-form";
import TextInput from "~/components/form/TextInput";
import SelectInput from "~/components/form/SelectInput";
import {Button, Select} from "@radix-ui/themes";
import Alert from "~/components/Alert";
import ClientCheckbox from "~/components/workflow/ClientCheckbox";
import { Workflow } from "~/types";
import { WorkflowCreateRequest } from "~/types/api/Workflow";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";
import { usePlandalf } from "@plandalf/react-plandalf-js";

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}
const DEFAULT_STATE = {
  name: 'Paywall Workflow',
  feature_id: null,
  triggers: JSON.stringify([
    {
      listen: 'attachment',
      event: 'click',
      qualifier: 'findButtonByText',
      props: {
        text: 'Create Business',
      },
    },
    {
      listen: 'custom',
      event: 'RollupButtonClicked',
    }
  ], null, 4),
  clients: []
}

export default function WorkflowCreatePage() {

  const {data: clients, error: clientsError} = useSWR(`/clients`, getFetcher<{data: Workflow['clients']}>);
  const [errors, setErrors] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const { plandalf, state } = usePlandalf();

  useEffect(() => {
    // shall('feature').pass() 
      // if not releaesd
    // plandalf.check('feature', 'workflow_trigger', 1)
    //   .then((res) => {
    //     console.log(res);
    //   })
    window.pd = plandalf;
  }, [plandalf]);

  const [data, setData] = useState<WorkflowCreateRequest>(DEFAULT_STATE);

  // await plandalf.check('feature', 'workflow_trigger', 1)
    // passes immediately if true, otherwise waits for upgrade

  function handleTriggersChange(value) {
    setData({...data, triggers: value});
  }

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setErrors([]);
    setIsSaving(true);

    // can consume? are they limited? 

    //plandalf.gate('')

    // 
    // if (!plandalf.can.consume('workflow_trigger', data.triggers.length)) {
    //   // generate flags from the api?
    //   setErrors({
    //     triggers: 'You do not have enough workflow triggers to create this workflow. Please upgrade your plan.'
    //   })
    //   setIsSaving(false);
    //   return;
    // }

    apiClient.post(`/convert/workflows`, data).then(res => {
      navigate(`/convert/workflows/${res.data.id}`);
    }).catch(err => {
      setIsSaving(false);
      console.error("Failed to create workflow", );
      setErrors(err.response.data.errors || [err.response.data.message]);
    })
  }

  const triggerDescriptors = useMemo(() => {
    let triggers = [];
    try {
      triggers = JSON.parse(data.triggers);
    } catch (e) {
      return null;
    }

    // turn the array of triggers into a readable sentence
    return triggers.map((trigger) => {
      if (!trigger.listen) {
        return null;
      }
      if (trigger.listen === 'custom') {
        return `When custom event "${trigger.event || ""}" is triggered`;
      }

      const qualifier = qualifierMap[trigger.qualifier] || trigger.qualifier;
      const event = eventMap[trigger.event] || trigger.event;
      const props = Object.keys(trigger.props || {}).map((key) => {
        return `${key} "${trigger.props[key]}"`;
      }).join(' or ');

      return `When ${qualifier} ${props} ${event}`;
    }).filter(f => f);
  }, [data.triggers]);

  const {data: featuresData} = useSWR(`/catalog/features?per_page=9999`, getFetcher<{data: Feature[]}>);

  const features = featuresData?.data || [];
  const featureOptions = features?.map((feature) => ({
    name: feature.name,
    id: feature.id
  })) || [];
  featureOptions.unshift({name: 'None', id: null});

  if (!clients) return <div>Loading...</div>
  if (clients.meta.total === 0) return <Alert title="No clients found"></Alert>

  return (
    <div className="p-10 space-y-6">

      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert/workflows/">Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbPage>Create Workflow</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Form onSubmit={handleSubmit} className="space-y-4">
        <TextInput
          label="Name"
          id="name"
          value={data.name}
          onChange={(value) => setData({...data, name: value})}
          placeholder="Name"
          errors={errors?.name}
          autoFocus
        />

        {!triggerDescriptors && (
          <Alert title="Invalid trigger JSON" type="error">
            <p>There was an error parsing the triggers JSON. Please correct the JSON and try again.</p>
          </Alert>
        )}
        <ul>
          {/* separate these by OR  text */}
          {triggerDescriptors?.map((trigger, index) => {
            if (!trigger) return null;
            if (index > 0) {
              return (
                <li key={index} className="text-sm text-gray-700"><b>OR</b> {trigger}</li>
              )
            }
            return (
              <li key={index} className="text-sm text-gray-700">{trigger}</li>
            )
          })}
        </ul>

        <SelectInput
          label="Feature"
          id="feature_id"
          value={data.feature_id}
          onChange={(value) => setData({...data, feature_id: value})}
          options={featureOptions}
        />

        <div className="mb-6">
          <label className="FormLabel" htmlFor="radix-:r7:">Workflow Triggers</label>

          <JSONEditor
            jsonInput={data.triggers}
            errors={errors?.triggers}
            setJsonInput={handleTriggersChange}
          />
        </div>

        <Button type="submit">{isSaving ? '...Saving' : 'Create'}</Button>
      </Form>
    </div>
  )
}
