import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import PageHeader from "~/components/PageHeader";
import {useParams} from "react-router";
import { TextInputProps, ValueFormatter } from "~/components/form/TextInput";
import ErrorList from "~/components/ErrorList";
import TextInput from "~/components/form/TextInput";
import Debug from "~/components/Debug";
import CodeInput from "~/components/form/CodeInput";
import * as Form from "@radix-ui/react-form";
import { Workflow } from "~/types";
import ClientCheckbox from "~/components/workflow/ClientCheckbox";
import { WorkflowEditRequest } from "~/types/api/Workflow";
import {Button, Heading} from "@radix-ui/themes";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import cx from "classnames";
import {toast} from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "~/components/ui/breadcrumb"
import axios from "axios";
import SelectInput from "~/components/form/SelectInput";

export default function WorkflowEditPage() {

  const {workflowId} = useParams();

  const {data: workflow, error: workflowError, isLoading} = useSWR(`/convert/workflows/${workflowId}`, getFetcher<Workflow>);
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState<WorkflowEditRequest>(null);

  const {data: featuresData} = useSWR(`/catalog/features?per_page=9999`, getFetcher<{data: Feature[]}>);

  const features = featuresData?.data || [];
  const featureOptions = features?.map((feature) => ({
    name: feature.name,
    id: feature.id
  })) || [];
  featureOptions.unshift({name: 'None', id: null});

  console.log({featureOptions});

  useEffect(() => {
    if (!workflow) return;
    setData({
      name: workflow.name,
      lookup_key: workflow.id,
      feature_id: workflow.feature?.id || null,
      triggers: JSON.stringify(workflow.triggers, null, 2),
      images: workflow.images,
    });
  }, [workflow]);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setErrors([]);
    setIsSaving(true);

    const newData = {
      ...data,
      images: data.images?.map((image) => image.id).filter((id) => id !== null)
    }

    apiClient.put(`/convert/workflows/${workflowId}`, newData)
      .then(res => {
        toast.success('Workflow updated');
        navigate(`/convert/workflows/${res.data.id}`);
      })
      .catch(err => {
        setIsSaving(false);
        console.error("Failed to create workflow", );
        setErrors(err.response.data.errors || [err.response.data.message]);
      })
  }

  function field(name, label) : Pick<TextInputProps, 'value'|'onChange'|'label'> {
    return {
      value: data[name],
      onChange: (value) => setData({...data, [name]: value}),
      label,
    }
  }

  function handleAssetChange(images) {
    setData({...data, images: images?.map(i => i.asset)});
  }

  if (isLoading) return <div>Loading...</div>;
  if (!data) return null;

  return (
    <div className="p-10 space-y-6">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert">Upgrade & Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${workflow.id}`}>{workflow.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Edit Experience</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeader>
        <Heading as="h1" size={"6"}>Edit Workflow</Heading>
      </PageHeader>

      <Form.Root onSubmit={handleSubmit} className="space-y-4">
        <TextInput {...field('name', 'Name')}/>

        <TextInput 
          {...field('lookup_key', 'Lookup Key')}
          beforeChangeFormatter={[ValueFormatter.slug]}
        />

        <SelectInput
          label="Feature"
          id="feature_id"
          value={data.feature_id}
          onChange={(value) => setData({...data, feature_id: value})}
          options={featureOptions}
        />

        <div className="py-4">
          <label htmlFor="images">Images</label>

          {workflow && (
            <AssetManager assets={data.images} onChange={handleAssetChange}/>
          )}
        </div>

        <CodeInput {...field('triggers', 'Triggers')} />

        <ErrorList errors={errors}/>

        <div className="flex items-center justify-between">
          <Button
            loading={isSaving}
            type="submit"
          >Save</Button>
        </div>
      </Form.Root>

      <Debug {...data}/>
    </div>

  )
}

function AssetManager({ assets, onChange, onAssetRemove }) {

  const [value, setValue] = useState(assets?.map((asset) => ({
    asset,
    file: null,
    form: null,
    state: 'completed',
  })));
  // console.log('AssetManager', { assets, value })

  useEffect(() => {
    console.log('ASsetManager@useEffect', { value });
    onChange(value);
  }, [value]);

  function handleAssetRemove(assetId) {
    setValue((prev) => {
      return prev?.filter((item) => item.asset.id !== assetId);
    });
  }

  function handleNewUpload(upload) {
    console.log('handleNewUpload', { upload })
    setValue([...value, upload]);

    axios.put(upload.form.uploadUrl, upload.file, {
      headers: upload.headers,
      withCredentials: true,
      withXSRFToken: true,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setValue((prev) => {
          return prev.map((item) => {
            if (item.asset.id === upload.asset.id) {
              return {
                ...item,
                form: {
                  progress: percentCompleted,
                },
              };
            }
            return item;
          });
        });
      },
    })
      .then(() => {
        console.log("CHANGE STATE TO COMPLETED");
        setValue((prev) => {
          return prev.map((item) => {
            if (item.asset.id === upload.asset.id) {
              return {
                ...item,
                state: 'completed',
              };
            }
            return item;
          });
        });
      })
  }

  return (
    <div className={cx({
      "mb-4 border-2 border-dashed border-teal-200 p-4 bg-teal-50 transition-opacity hover:opacity-80 active:opacity-60": true,
    })}>
      {value?.map((item, key) => (
        <AssetItem {...item} key={key} onRemove={handleAssetRemove}/>
      ))}

      <UploadArea
        onNewUploadAvailable={handleNewUpload}
      />
      {/*<pre>{JSON.stringify(value, null, 2)}</pre>*/}
    </div>
  )
}


function UploadArea({ onDrop, onNewUploadAvailable }) {

  const fileInput = React.useRef(null);

  const onButtonClick = () => fileInput.current.click();

  const [error, setError] = useState(null);

  const validateImage = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      return 'Invalid file type. Only JPG, PNG, and GIF are allowed.';
    }

    if (file.size > maxSize) {
      return 'File size exceeds the limit of 5MB.';
    }

    return '';
  };

  const handleFileChange = (evt) => {
    setError(null);
    for (let file of evt.target.files) {
      const validationError = validateImage(file);

      if (validationError) {
        setError(validationError);
        return;
      }

      uploadFile(file)
    }
  };

  const uploadFile = async (file) => {
    console.log('uploadFile', { file })
    if (!file) {
      setError('No file selected');
      return;
    }

    try {
      // Get the signed URL from the backend
      const response = await apiClient.post('/media/assets', {
        original_name: file.name,
        content_type: file.type,
        file_size: file.size,
      });

      const { upload_url: uploadUrl, asset, headers } = response.data;

      if ('Host' in headers) {
        delete headers.Host;
      }

      onNewUploadAvailable({
        asset,
        headers,
        file,
        form: {
          uploadUrl,
          progress: 0,
        },
        state: 'pending',
      });

    } catch (error) {
      setError('Failed to upload file. Please try again.');
    }
  };

  // todo: add drag and drop?
  return (
    <div className="media-library-add">
      <button type="button" className="media-library-dropzonemedia-library-dropzone-add flex gap-4 items-center" onClick={onButtonClick}>
        <input
          type="file"
          accept="image/png,image/jpeg,application/pdf"
          className="hidden"
          ref={fileInput}
          onChange={handleFileChange}
        />
        <div className="media-library-placeholder">
          <PlusCircleIcon className="w-4 h-4"/>
        </div>
        <div className="media-library-help"><span>Select or drag max 1 file | PNG, JPEG | &lt; 1024KB</span>
        </div>
      </button>
      {error && <p style={{color: 'red'}}>{error}</p>}
    </div>
  )
}

function AssetItem({ state, file, asset, form, onRemove }) {

  function handleRemoveClick() {
    onRemove(asset.id);
  }

  const uploadProgress = form?.progress || 0;

  return (
    <div className="bg-white border">
      <div className="p-1  h-14 flex gap-4 justify-between">
        <div className="flex gap-2 items-center">
          <div className="border w-12 h-12">
            <img src={asset.href} alt=""/>
          </div>

          <div>
            {asset.name}
            {asset.size}
            {asset.content_type}
          </div>
          <div>{state}</div>

          {uploadProgress > 0 && (
            <div className="text-xs bg-gray-100">
              <p>Upload Progress: {uploadProgress}%</p>
              <progress value={uploadProgress} max="100"></progress>
            </div>
          )}
        </div>

        <div className="flex items-center">
          {state === 'completed' && (
            <Button color="red" type="button" onClick={handleRemoveClick}>Remove</Button>
          )}
        </div>
      </div>
      <div className="text-xs p-1">
        <a href={asset.href} target="_blank">{asset.href}</a>
      </div>
    </div>
  )
}
