import {useParams} from "react-router";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import PageHeader from "~/components/PageHeader";
import SelectInput from "~/components/form/SelectInput";
import * as Form from "@radix-ui/react-form";
import {Button} from "@radix-ui/themes";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function SchemePackageCreatePage() {

  const { schemeId } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { data: scheme } = useSWR(`/pricing/schemes/${schemeId}`, getFetcher);

  // load all products
  // exclude products that are already in the scheme
  const { data: products, error: productsError } = useSWR('/catalog/products', getFetcher);

  const [data, setData] = useState({
    product: null,
  });

  const filteredProducts = products?.data.filter(product => {
    return !scheme.products.find(p => p === product.id)
  }, [products, scheme]);
  function handleSubmit(evt) {
    evt.preventDefault();

    apiClient.patch(`/pricing/schemes/${schemeId}`, data)
      .then(() => {
        navigate(`/catalog/pricing/schemes/${schemeId}`);
      })
      .catch(e => {
        console.log('e', e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (!scheme || !filteredProducts) return <div>loading</div>

  // what if i want to add a product, currency OR interval to the scheme?

  return (
    <div>
      <PageHeader>Create Package for: {scheme.name}</PageHeader>

      <div className="p-4">
        <h2 className="text-lg font-semibold">Add Package</h2>

        <Form.Root onSubmit={handleSubmit}>
          <SelectInput
            options={filteredProducts}
            name="product"
            value={data.product}
            onValueChange={value => setData({...data, product: value})}
            id="product"
          />

          <Button>Submit</Button>
        </Form.Root>
      </div>
    </div>
  )
}
