import PageHeader from "~/components/PageHeader";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";

import * as Form from "@radix-ui/react-form";
import {Button, Callout, CheckboxGroup, Heading, Table} from "@radix-ui/themes";
import TextInput from "~/components/form/TextInput";
import React, {useMemo} from "react";
import {FormField} from "@radix-ui/react-form";
import {flatMap} from "lodash";
import {useNavigate} from "react-router-dom";

const CURRENCIES = [
  { id: 'USD', name: '🇺🇸 USD - United States Dollar' },
  { id: 'EUR', name: '🇪🇺 EUR - Euro' },
  { id: 'GBP', name: '🇬🇧 GBP - British Pound'},
  { id: 'JPY', name: '🇯🇵 JPY - Japanese Yen' },
  { id: 'AUD', name: '🇦🇺 AUD - Australian Dollar' },
  { id: 'CAD', name: '🇨🇦 CAD - Canadian Dollar' },

];
const INTERVALS = [
  { id: 'P1M', name: 'Month' },
  { id: 'P1Y', name: 'Year' },
];

export default function PricingSchemeCreatePage() {

  const [data, setData] = React.useState({
    // intervals: [],
    // currencies: [],
    products: [],
    name: null,
    // lookup_key: null,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const navigate = useNavigate();
  const { data: products } = useSWR('/catalog/products?limit=9999', getFetcher);

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors(null);
    setIsSubmitting(true);
    apiClient.post(`/pricing/schemes`, data)
      .then(res => {
        console.log({res});
        navigate(`/catalog/pricing/schemes/${res.data.id}`);
      })
      .catch(err => {
        console.error(err);
        setErrors(err.response.data.errors || [err.response.data.message]);
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }

  function handleIntervalChange(newIntervals) {
    setData({ ...data, intervals: newIntervals });
  }

  // const productsToUse = data.products.length === 0 ? [null] : data.products;

  // const matrix = useMemo(() => {
  //   return flatMap(productsToUse, product => {
  //     console.log("product", product);
  //     return flatMap(data.intervals, interval => {
  //       return data.currencies.map(currency => ({
  //         product_id: product,
  //         interval: interval,
  //         currency: currency
  //       }))
  //     })
  //   });
  // }, [productsToUse, data.intervals, data.currencies]);

  // function getProduct(id) {
  //   return products.data.find(p => p.id === id);
  // }

  return (
    <div className="p-10">
     <Heading as="h1" size="6">Create Pricing Scheme</Heading>

      <Form.Root onSubmit={handleSubmit} className="space-y-2">

        <TextInput
          value={data.name}
          onChange={v => setData({ ...data, name: v})}
          label="Name"
          errors={errors?.name}
        />

        {/*<TextInput*/}
        {/*  value={data.lookup_key}*/}
        {/*  onChange={v => setData({ ...data, lookup_key: v})}*/}
        {/*  beforeChangeFormatter={['slug']}*/}
        {/*  label="Lookup Key"*/}
        {/*  errors={errors?.lookup_key}*/}
        {/*/>*/}

        {products && (
          <FormField>
            <Form.Label className="FormLabel">
              Product
              {/*{validationError && <span className="ml-2 text-red-700 text-xs">*{validationError}</span>}*/}
            </Form.Label>
            <CheckboxGroup.Root
              value={data.products}
              name="products"
              onValueChange={v => setData({ ...data, products: v })}
            >
              {products.data.map(product => (
                <CheckboxGroup.Item value={product.id}>{product.name}</CheckboxGroup.Item>
              ))}
            </CheckboxGroup.Root>
            {products.data.length === 0 && (
              <Callout.Root>No products found, try importing from stripe?</Callout.Root>
            )}
          </FormField>
        )}

        <Button loading={isSubmitting}>Submit</Button>
      </Form.Root>
      {/*<pre>{JSON.stringify(matrix,null,2)}</pre>*/}
      {/*<pre>{JSON.stringify(data,null,2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors,null,2)}</pre>*/}
    </div>
  )
}
