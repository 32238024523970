import PageHeader from "~/components/PageHeader";
import {Heading, TabNav} from "@radix-ui/themes";
import AnchorButton from "~/components/AnchorButton";
import React from "react";
import {Link, useLocation} from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList, BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";

export default function WorkflowLayout({ workflow, children }) {

  const location = useLocation();

  return (
    <div className="flex flex-col flex-grow mb-10">
      <div className="px-10">
        <PageHeader>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild >
                  <Link to="/convert/workflows/">Upgrade &amp; Checkout</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator/>
              <BreadcrumbItem>
                <BreadcrumbPage>{workflow.name}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="gap-2 flex">
            {/* <AnchorButton variant="outline" href={`/convert/workflows/${workflow.id}/edit`}>Duplicate</AnchorButton> */}
            <AnchorButton variant="surface" href={`/convert/workflows/${workflow.id}/edit`}>Edit </AnchorButton>
            {/* <AnchorButton variant="outline" href={`/convert/workflows/${workflow.id}/edit`}>Pause</AnchorButton> */}
          </div>
        </PageHeader>

        <div className="mb-4">
          <TabNav.Root size="2">
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}`}>
              <Link to={`/convert/workflows/${workflow.id}`}>Results</Link>
            </TabNav.Link>
            <TabNav.Link  asChild active={location.pathname === `/convert/workflows/${workflow.id}/paywalls`}>
              <Link to={`/convert/workflows/${workflow.id}/paywalls`}>Variants</Link>
            </TabNav.Link>
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}/participation`}>
              <Link to={`/convert/workflows/${workflow.id}/participation`}>Sessions</Link>
            </TabNav.Link>
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}/revenue`}>
              <Link to={`/convert/workflows/${workflow.id}/revenue`}>Revenue</Link>
            </TabNav.Link>
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}/events`}>
              <Link to={`/convert/workflows/${workflow.id}/events`}>Events</Link>
            </TabNav.Link>
          </TabNav.Root>
        </div>
      </div>

      {children}
    </div>
  )
}
