import {useParams} from "react-router";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import Debug from "~/components/Debug";

import {Box, Button, Flex, Heading, Link, Table, CheckboxCards, Text, SegmentedControl, Badge} from "@radix-ui/themes";
import AnchorButton from "~/components/AnchorButton";
import * as Form from "@radix-ui/react-form";

import {
  Sheet, SheetClose,
  SheetContent,
  SheetDescription, SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui/sheet"
import TextInput from "~/components/form/TextInput";
import React, {useMemo, useState} from "react";
import {uniq} from "lodash";
import cx from "classnames";
import {toast} from "react-toastify";
import {Tab} from "@headlessui/react";
import DateComponent from "~/components/DateComponent";
import {PlanStatusBadge} from "~/components/ui/PlanStatusBadge";
import ProductStatusBadge from "~/components/ui/ProductStatusBadge";

export function SheetDemo({ scheme, mutate }) {

  // list all the selected products at the top

  const defaultSelectedProducts = useMemo(() => {
    return uniq(scheme.packages.data.map(pkg => pkg.product_id));
  }, [scheme.packages]);

  const [data, setData] = useState({
    products: defaultSelectedProducts,
  });

  const { data: products } = useSWR('/catalog/products', getFetcher);

  function handleCheckboxChange(value) {
    setData({...data, products: value});
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    console.log("submit")
    apiClient.patch(`/pricing/schemes/${scheme.id}`, {
      products: data.products
    }).then(res => {
      console.log('res', res);
      closeButton.current.click();
      mutate();
      toast.success('Scheme updated');
    }).catch(err => {
      console.error('err', err);
    })
  }

  const closeButton = React.useRef(null);

  return (
    <Sheet>
      <SheetTrigger asChild >
        <Button variant="outline" ref={closeButton}>Edit Products</Button>
      </SheetTrigger>
      <SheetContent className="w-[540px] h-screen overflow-y-auto">
        <Form.Root onSubmit={handleSubmit}>
          <SheetHeader>
            <SheetTitle>Add or remove products</SheetTitle>
            <SheetDescription >
              Make changes to your pricing scheme here. Click save when you're done.
            </SheetDescription>
            <br/>
          </SheetHeader>

          <Box maxWidth="600px" className="mb-4">

            {/*<div className="mb-4">*/}
            {/*  <TextInput label="Search" value={""} autoComplete={"off"}/>*/}
            {/*</div>*/}

            <CheckboxCards.Root
              variant="surface"
              defaultValue={data.products}
              gap="1"
              size="1"
              columns={{ initial: '1' }}
              onValueChange={handleCheckboxChange}
            >
              {products?.data.map(product => (
                <CheckboxCards.Item key={product.id} value={product.id} className={cx({
                  'bg-teal-100': data.products.includes(product.id),
                })}>
                  <Text weight="bold">{product.name}</Text>
                </CheckboxCards.Item>
              ))}
            </CheckboxCards.Root>
          </Box>

          {/*<pre>{JSON.stringify(data,null,2)}</pre>*/}

          <SheetFooter className=" sticky bottom-0 bg-white inset" >
            <Button >Save changes</Button>
          </SheetFooter>

        </Form.Root>
      </SheetContent>
    </Sheet>
  )
}

function OfferingDetailComponent({ scheme, mutate }) {

  const [data, setData] = useState({
    name: scheme.name,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit(evt) {
    evt.preventDefault();
    setIsSubmitting(true);
    apiClient
      .patch(`/pricing/schemes/${scheme.id}`, {name: data.name})
      .then(res => {
        console.log('res', res);
        mutate();
        toast.success('Scheme updated');
        setIsSubmitting(false);
      }).catch(err => {
        console.error('err', err);
        setIsSubmitting(false);
      });
  }

  return (
    <div className="space-y-2">
      <Heading as="h2" size="4" weight="medium">Name</Heading>

      <Form.Root onSubmit={handleSubmit} className="space-y-4">
        <TextInput
          value={data.name}
          onChange={value => setData({...data, name: value})}
          className="max-w-96"
        />

        <Button loading={isSubmitting}>Update</Button>
      </Form.Root>
    </div>
  )
}

function OfferingPackagesListComponent({ scheme, mutate, plans }) {
  function planForPackage(pkg) {
    return plans.data.find(plan => plan.package?.id === pkg.id);
  }

  const [view, setView] = useState('packages');

  if (!scheme) {
    return;
  }

  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <div className="space-y-4 mb-4">
          <Heading as="h2" size="4" weight="medium">Packages ({scheme.packages.data.length})</Heading>

          <SegmentedControl.Root variant="surface" value={view} onValueChange={setView}>
            <SegmentedControl.Item value="packages">Packages</SegmentedControl.Item>
            <SegmentedControl.Item value="plans">Plans</SegmentedControl.Item>
          </SegmentedControl.Root>
        </div>

        <SheetDemo
          scheme={scheme}
          mutate={mutate}
        />
      </div>

      {view === 'packages' && (
        <Table.Root variant="surface" size="1">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Package</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Date Published</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Last Updated</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Plans #</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Schedules</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {scheme.packages.data.map((product, i) => {
              const plans = planForPackage(product);

              return (
                <Table.Row key={i}>
                  <Table.RowHeaderCell>
                    <Link href={`/catalog/products/${product.id}`} weight="medium" color="gray" highContrast>{product.name}</Link>
                  </Table.RowHeaderCell>
                  <Table.Cell>
                    <ProductStatusBadge status={product.status}/>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{product.date_published}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{product.last_updated}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{plans.length}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Link href={`/catalog/products/${product.id}/schedules`}>View schedules</Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table.Root>
      )}

      {view === 'plans' && (
        <>
          <Table.Root variant="surface" size="1">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell colSpan={2}>Package</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Interval</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Currency</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Published At</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Date updated</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Schedules</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {plans.data.map((plan, i) => (
                <Table.Row>
                  <Table.RowHeaderCell colSpan={2}>
                    <Link href={`/catalog/products/${plan.package.id}`}  color="gray" highContrast underline={"hover"}>
                      <div className="font-medium">{plan.package?.name}</div>
                    </Link>
                    <div className="text-gray-600">{plan.id}</div>
                  </Table.RowHeaderCell>
                  <Table.Cell>
                    <PlanStatusBadge status={plan.status}/>
                  </Table.Cell>
                  <Table.Cell>
                    {plan.renew_interval}
                  </Table.Cell>
                  <Table.Cell>
                    {plan.currency}
                  </Table.Cell>

                  <Table.Cell>
                    {plan.published_at && (<DateComponent timestamp={plan.published_at}/>)}
                  </Table.Cell>
                  <Table.Cell>
                    {plan.updated_at && (<DateComponent timestamp={plan.updated_at}/>)}
                  </Table.Cell>

                  <Table.Cell>{plan.schedules_count}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </>
      )}
    </div>
  )
}

export default function PricingSchemeDetailPage() {

  const {schemeId} = useParams();

  const {
    data: scheme,
    error,
    mutate
  } = useSWR(`/pricing/schemes/${schemeId}`, url => apiClient.get(url).then(res => res.data));
  const {
    data: plans,
    error: plansError
  } = useSWR(`/pricing/schemes/${schemeId}/plans`, url => apiClient.get(url).then(res => res.data));

  function intervalSuffix(interval) {
    return scheme.intervals.find(i => i.value === interval)?.priceSuffix || interval;
  }

  function planForPackage(pkg) {
    return plans.data.find(plan => plan.id === pkg.plan_id);
  }

  if (error) return <div>Failed to load offering</div>
  if (!scheme) return <div>Loading scheme...</div>
  if (plansError) return <div>Failed to load plans</div>
  if (!plans) return <div>Loading plans...</div>


  return (
    <div className="p-10 space-y-4">

      <OfferingDetailComponent
        scheme={scheme}
        mutate={mutate}
      />


      <OfferingPackagesListComponent
        scheme={scheme}
        mutate={mutate}
        plans={plans}
      />
    </div>
  )
}
