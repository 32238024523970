import React from "react";
import {Link} from "react-router-dom";

export type PageHeaderProps = {
  children: React.ReactNode;
  navigation?: React.ReactNode;
}

function PageHeader({ children, navigation }: PageHeaderProps) {
  return (
    <div className=" h-16 flex justify-start gap-2">
      {navigation}
      <div className="flex items-center  justify-between w-full">
        {children}
      </div>
    </div>
  )
}

export default PageHeader;
