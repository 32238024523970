import { useParams } from "react-router";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import Alert from "~/components/Alert";
import * as Form from "@radix-ui/react-form";
import { Button } from "@radix-ui/themes";
import SelectInput from "~/components/form/SelectInput";
import TextInput from "~/components/form/TextInput";
import { useState } from "react";
import { Link } from "react-router-dom";

const planTypes = ["custom", "standard", "offer", "provisional", "addon"];


function PlanListItem({ plan, onSave, mutate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlan, setEditedPlan] = useState({
    display_name: plan.display_name,
    type: plan.type,
  });

  const handleEdit = () => setIsEditing(true);

  const handleChange = (field, value) => {
    setEditedPlan(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      await onSave({ ...plan, ...editedPlan });
      mutate();
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving plan:", error);
    }
  };

  return (
    <li className="bg-white shadow rounded-lg p-4">
      <div className="flex justify-between items-center">
        <Link to={`/catalog/pricing/plans/${plan.id}`} className="font-semibold">{plan.id}</Link>
        <span>{plan.name}</span>
      </div>
      {isEditing ? (
        <Form.Root onSubmit={handleSave} className="space-y-4">
          <div className="space-y-1">
            <span>Display name</span>
            <TextInput
              value={editedPlan.display_name}
              onChange={(value) => handleChange('display_name', value)}
            />
          </div>

          <div className="space-y-1">
            <span>Type</span>
            <SelectInput
              options={planTypes.map(type => ({ id: type, name: type }))}
              value={editedPlan.type}
              onValueChange={(value) => handleChange('type', value)}
            />
          </div>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Form.Root>
      ) : (
        <div className="mt-2 space-y-2">
          <p>Display Name: {plan.display_name}</p>
          <p>Type: {plan.type}</p>
          <Button onClick={handleEdit} variant="secondary">
            Edit
          </Button>
        </div>
      )}
    </li>
  );
}

export default function PackageDetailPage() {
  const { packageId } = useParams();
  const {
    data: pkg,
    error,
    mutate
  } = useSWR(`/pricing/packages/${packageId}`, getFetcher);

  if (!pkg) return null;

  const handleSave = async (plan) => {
    try {
      await apiClient.patch(`/pricing/plans/${plan.id}`, {
        display_name: plan.display_name,
        type: plan.type,
      });
      mutate();
    } catch (error) {
      console.error("Error updating plan: ", error);
    }
  };

  return (
    <div className="p-4">
      {error && (
        <Alert type="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      <h2 className="text-2xl font-bold mb-4">Package: {pkg.name}</h2>
      <ul className="space-y-4">
        {pkg.plans.map((plan) => (
          <PlanListItem
            key={plan.id}
            plan={plan}
            onSave={handleSave}
            mutate={() => mutate()}
          />
        ))}
      </ul>
    </div>
  );
}
