import PageHeader from "~/components/PageHeader";
import useSWR from "swr";
import apiClient from "~/lib/apiClient";
import Debug from "~/components/Debug";
import {Link, useSearchParams} from "react-router-dom";

import {Flex, Text, Button, Table, Avatar, Heading} from '@radix-ui/themes';
import React, {useMemo} from "react";
import AnchorButton from "~/components/AnchorButton";
import {useParams} from "react-router";
import { format, fromUnixTime } from 'date-fns';

function Pagination({collection}) {

  const prevPage = useMemo(() => {
    if (collection.meta.current_page === 1) {
      return 1;
    }

    return collection.meta.current_page - 1;
  }, [collection]);

  const nextPage = useMemo(() => {
    if (collection.meta.current_page === collection.meta.last_page) {
      return collection.meta.last_page;
    }

    return collection.meta.current_page + 1;
  }, [collection]);

  return (
    <div className="flex justify-between px-4 py-2">
      <div className="flex items-center">
        <b>Showing {collection.meta.from} to {collection.meta.to}</b>&nbsp;of {collection.meta.total} results
      </div>

      <div className="flex gap-2">
        <AnchorButton
          href={`/customers?page=${prevPage}`}
          disabled={collection.meta.current_page === 1}>Prev</AnchorButton>
        <AnchorButton
          href={`/customers?page=${nextPage}`}
          disabled={collection.meta.current_page === collection.meta.last_page}>Next</AnchorButton>
      </div>
    </div>
  )
}

function usePagination() {
  const [searchParams] = useSearchParams();

  return {
    page: Number(searchParams.get("page")) || 1
  };
}

export default function CustomersListPage() {

  // list for prod and test ? or just show ?

  // get page from useParams if page is not set, default to 1
  const { page } = usePagination();

  const { data: collection, error } = useSWR(
    `/customers?page=${page}`,
      url => apiClient.get(url).then(res => res.data)
  );

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    try {
      // Assuming the timestamp is in seconds. If it's in milliseconds, remove the * 1000
      const date = fromUnixTime(timestamp);
      return format(date, 'yyyy-MM-dd');
    } catch (error) {
      console.error('Error formatting date:', error);
      return String(timestamp); // Return original timestamp as string if parsing fails
    }
  };

  function avatarLetters(customer) {
    if (customer.name) {
      return customer.name?.split(' ').map((n) => n[0]).join('').toUpperCase();
    }

    if (customer.email) {
      return customer.email[0].toUpperCase();
    }

    return '';
  }

  if (!collection) return <div>Loading...</div>;

  return (
    <div className="p-10">
      <PageHeader>
        <Heading>Customers</Heading>
        <div>{collection?.meta.total} Customers</div>
      </PageHeader>

      {error && <div>Failed to load</div>}
      {!collection && <div>Loading...</div>}

      {collection && (
      <div className="">
        <Table.Root variant="surface">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Customer ID</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Created At</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {collection.data.map(customer => (
              <Table.Row key={customer.id}>
                <Table.RowHeaderCell>
                  <Avatar
                    src={customer.hostname ? `https://logo-service.plandalf.dev/api/logo?url=${customer.hostname}` : null}
                    fallback={avatarLetters(customer)}
                    size={"1"}
                  />

                  <Link className="px-2" to={`/customers/${customer.id}`}>{customer.reference_id}</Link>
                </Table.RowHeaderCell>
                <Table.Cell>{customer.email}</Table.Cell>
                <Table.Cell>{customer.name}</Table.Cell>
                <Table.Cell>{formatDate(customer.reference_created_at)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </div>
      )}

      {collection.meta.total === 0 && (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No Customers</h3>

        </div>
      )}

      <Pagination collection={collection}/>
      {/*<Debug {...collection}/>*/}
    </div>
  )
}
