import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {useAuth} from "~/hooks/useAuth";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import {Heading} from "@radix-ui/themes";

export default function TeamListPage() {

  const { user } = useAuth();
  const { data: memberships, isLoading } = useSWR(`/mgmt/organizations/${user?.current_organization.id}/memberships`, getFetcher)

  if (isLoading) return 'loading';

  return (
    <div className="p-6 space-y-6">

      <Form.Root className="space-y-2">
        <Heading as="h3" size="4">Invite Code</Heading>
        <TextInput type="text" value={user?.current_organization.invite_code} readOnly />
      </Form.Root>

      <div  className="space-y-2">
        <Heading as="h3" size="4">Members</Heading>
        {!memberships && 'No memberships found'}
        {memberships && (
          <div>
            {memberships?.data.map((membership) => (
              <div key={membership.id} className="flex gap-2">
                <div>{membership.email}</div>
                <div>{membership.pivot.role}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
