import {useParams} from "react-router";
import useSWR, {mutate} from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {Link} from "react-router-dom";
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Select, Flex, Button, Card, Text, Badge } from '@radix-ui/themes';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import {get} from "lodash";
import {Heading, Table, TabNav, Dialog, Text, Switch} from "@radix-ui/themes";
import React, {useRef} from "react";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import update from "immutability-helper";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BarChartBigIcon, CloudLightning, CloudLightningIcon, CodeIcon, TextCursorIcon } from "lucide-react";

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PAYWALL_BASE_URL = process.env.PLANDALF_PAYWALL_URL;

export function Alert({children}) {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            {children}
          </p>
        </div>
      </div>
    </div>
  )
}

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}

function GlobalStatsComponent({ stats }) {
  return (
    <div className="grid grid-cols-5 gap-4 bg-gray-950 text-white rounded-md h-30 divide-x divide-gray-900">
      <StatItem label="Unique Views" value={stats?.unique_views || '0'} />
      <StatItem label="Bounce rate" value={stats?.bounce_rate ? `${stats?.bounce_rate}%` : `-`} />
      <StatItem label="Conversions" value={stats?.total_conversions || '0'} />
      <StatItem label="Conversion Rate" value={stats?.conversion_rate ? `${stats.conversion_rate}%` : '-'} />
      <StatItem label="Total Revenue" value={stats?.total_revenue || '-'} />
    </div>
  );
}

function StatItem({ label, value }) {
  return (
    <div className="flex flex-col items-center space-y-1 p-4 justify-between">
      <div className="text-4xl font-medium">{value}</div>
      <div className="text-xs font-semibold tabular-nums">{label}</div>
    </div>
  );
}

function formatTime(seconds) {
  if (!seconds) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

function DateRangeFilter({ workflow }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState('last_30_days');
  const [client, setClient] = useState('');
  const [customRange, setCustomRange] = useState({ from: '', to: '' });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setRange(params.get('range') || 'last_30_days');
    setClient(params.get('client') || '');
    if (params.get('range') === 'custom') {
      setCustomRange({
        from: params.get('from') || '',
        to: params.get('to') || ''
      });
    }
  }, [location]);

  const updateFilters = (newRange, newClient, newCustomRange) => {
    const params = new URLSearchParams();
    params.set('range', newRange);
    if (newClient) params.set('client', newClient);
    if (newRange === 'custom') {
      params.set('from', newCustomRange.from);
      params.set('to', newCustomRange.to);
    }
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Flex gap="3" align="center">
      <Select.Root value={range} onValueChange={(value) => updateFilters(value, client, customRange)}>
        <Select.Trigger />
        <Select.Content>
      
          <Select.Item value="today">Today</Select.Item>
          <Select.Item value="yesterday">Yesterday</Select.Item>
          <Select.Item value="last_7_days">Last 7 Days</Select.Item>
          <Select.Item value="last_30_days">Last 30 Days</Select.Item>
          <Select.Item value="last_365_days">Last 365 Days</Select.Item>
          <Select.Item value="this_month">This Month</Select.Item>
          <Select.Item value="last_month">Last Month</Select.Item>
          <Select.Item value="this_year">This Year</Select.Item>
          <Select.Item value="last_year">Last Year</Select.Item>
          <Select.Item value="all_time">All Time</Select.Item>
          <Select.Item value="custom">Custom Range</Select.Item>
        </Select.Content>
      </Select.Root>

      {range === 'custom' && (
        <Flex gap="2">
          <input
            type="date"
            value={customRange.from}
            onChange={(e) => setCustomRange({ ...customRange, from: e.target.value })}
          />
          <input
            type="date"
            value={customRange.to}
            onChange={(e) => setCustomRange({ ...customRange, to: e.target.value })}
          />
          <Button onClick={() => updateFilters(range, client, customRange)}>
            Apply
          </Button>
        </Flex>
      )}

      <Select.Root 
      
        value={client}
        onValueChange={(value) => updateFilters(range, value, customRange)}>
        <Select.Trigger placeholder="All Clients"/>
        <Select.Content>
          {/* <Select.Item value="all">All Clients</Select.Item> */}
          {workflow.rollouts?.map((rollout) => (
            <Select.Item key={rollout.client.id} value={rollout.client.id.toString()}>
              {rollout.client.name}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <Button variant="outline" onClick={() => updateFilters('today', '', { from: '', to: '' })}>
        Reset
      </Button>
    </Flex>
  );
}

function LineGraph({ chartData }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 6,
          autoSkip: true,
        },
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Views',
        data: chartData.datasets.find(d => d.label === 'Views').data,
        borderColor: '#3b82f6',
        backgroundColor: '#3b82f6',
        fill: 'start',
        order: 3,
      },
      {
        label: 'Unique Users',
        data: chartData.datasets.find(d => d.label === 'Unique Users').data,
        borderColor: '#60a5fa',
        backgroundColor: '#60a5fa',
        fill: 'start',
        order: 2,
      },
      {
        label: 'Conversions',
        data: chartData.datasets.find(d => d.label === 'Conversions').data,
        borderColor: '#189ab4',
        backgroundColor: '#189ab4',
        fill: 'start',
        order: 1,
      },
    ],
  };

  return (
    <div style={{ height: '220px' }}>
      <Line options={options} data={data} />
    </div>
  );
}

function TriggerCard({ trigger, index }) {
  const getTriggerIcon = (listen) => {
    switch (listen) {
      case 'attachment':
        return <TextCursorIcon className="h-5 w-5 text-blue-500" />;
      case 'custom':
        return <CodeIcon className="h-5 w-5 text-purple-500" />;
      default:
        return <CloudLightning className="h-5 w-5 text-yellow-500" />;
    }
  };

  return (
    <Card className="mb-4">
      <Flex align="center" gap="3" className="">
        {getTriggerIcon(trigger.listen)}
        <Text weight="bold">Trigger {index + 1}</Text>
        <div className="flex gap-2 flex-wrap">
          <Badge color="blue"><Text>{listenMap[trigger.listen] || trigger.listen}</Text></Badge>
          
          {trigger.qualifier && (
              <Badge color="green"><Text>{qualifierMap[trigger.qualifier] || trigger.qualifier}</Text></Badge>
          )}
          {trigger.props && Object.keys(trigger.props).length > 0 && (
              <Badge color="purple"><Text>{Object.entries(trigger.props).map(([key, value]) => `${key}: "${value}"`).join(', ')}</Text></Badge>
          )}
          {trigger.event && (
              <Badge color="orange"><Text>{eventMap[trigger.event] || trigger.event}</Text></Badge> 
          )}
        </div>
      </Flex>

    </Card>
  );
}

export default function WorkflowDetailPage() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const queryString = useMemo(() => {
    const range = searchParams.get('range') || 'last_30_days';
    const client = searchParams.get('client') || '';
    const from = searchParams.get('from') || '';
    const to = searchParams.get('to') || '';
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const queryParams = new URLSearchParams();
    if (range) queryParams.set('range', range);
    if (client) queryParams.set('client', client);
    if (from) queryParams.set('from', from);
    if (to) queryParams.set('to', to);
    if (tz) queryParams.set('tz', tz);

    return queryParams.toString();
  }, [searchParams]);

  const {data: workflow, error, mutate: mutateWorkflow} = useSWR(`/convert/workflows/${params.id}`, getFetcher);

  const {data: results, error: resultsError} = useSWR(
    `/convert/workflows/${params.id}/results${queryString ? `?${queryString}` : ''}`,
    getFetcher
  );

  const triggerDescriptors = useMemo(() => {
    let triggers = workflow?.triggers || [];
    // turn the array of triggers into a readable sentence
    return triggers.map((trigger) => {
      if (!trigger.listen) {
        return null;
      }

      if (trigger.listen === 'custom') {
        return <span>When custom event <b>"{trigger.event || ""}"</b> is triggered</span>
      }

      const qualifier = qualifierMap[trigger.qualifier] || trigger.qualifier;
      const event = eventMap[trigger.event] || trigger.event;
      const props = Object.keys(trigger.props || {}).map((key) => {
        return `${key} "${trigger.props[key]}"`;
      }).join(' or ');

      return `When ${qualifier} ${props} ${event}`;
    }).filter(f => f);
  }, [workflow?.triggers]);

  if (error) return <div>
    <div>Failed to load</div>
    {JSON.stringify(error, null, 2)}
  </div>;

  if (!workflow) return <div>Loading...</div>;

  return (
    <WorkflowLayout workflow={workflow}>

      <div className="space-y-2 px-10 pt-4">

        {/* Add filters here */}
        <DateRangeFilter workflow={workflow} />

        <GlobalStatsComponent stats={results?.global_stats}/>

        {/* Add the LineGraph component here */}
        {results?.chart_data && (
          <div className="bg-gray-50 p-4 rounded-lg h-[280px]">
            <Heading size="3" mb="2">Performance Over Time</Heading>
            <LineGraph chartData={results.chart_data} />
          </div>
        )}

        <div className="space-y-3 py-6">
          <div className="div">
            <Heading>Results</Heading>
            {/*<p>Since July 20, 12:00pm | Updated every 5 minutes</p>*/}
          </div>

          {results?.data && results.data.length > 0 ? (
            <Table.Root variant="surface">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
                  {/* <Table.ColumnHeaderCell>Weight</Table.ColumnHeaderCell> */}
                  <Table.ColumnHeaderCell>Proceeds</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Per User</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Total Views</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Unique Views</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Conversions</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Conv Rate</Table.ColumnHeaderCell>
                  {/* <Table.ColumnHeaderCell>Win %</Table.ColumnHeaderCell> */}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {/*<pre>{JSON.stringify(results,null,2)}</pre>*/}
                {results?.data?.map(result => (
                  <Table.Row>
                    <Table.Cell>
                      <Link to={`/convert/paywalls/${result.id}/edit`} className="font-medium hover:underline">{result.name}</Link>
                    </Table.Cell>
                    {/* <Table.Cell>{result.weight ? `${result.weight}%` : '-'}</Table.Cell> */}
                    <Table.Cell>{result.proceeds_total_formatted}</Table.Cell>
                    <Table.Cell>{result.proceeds_per_user_formatted}</Table.Cell>
                    {/* <Table.Cell>{result.users_count}</Table.Cell> */}
                    <Table.Cell>{result.views_count}</Table.Cell>
                    <Table.Cell>{result.views_unique_count}</Table.Cell>
                    <Table.Cell>{result.conversions_count}</Table.Cell>
                    <Table.Cell>{result.conversion_rate ? `${result.conversion_rate}%` : '-'}</Table.Cell>
                    {/* <Table.Cell>{result.win_rate ? `${result.win_rate}%` : '-'}</Table.Cell> */}
                  </Table.Row>
                ))}

              </Table.Body>
            </Table.Root>
          ) : (
            <div className="text-center py-8 bg-gray-50 rounded-lg">
              <BarChartBigIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No results yet</h3>
              <p className="mt-1 text-sm text-gray-500">
                Results will appear here once your workflow starts collecting data.
              </p>
            </div>
          )}
        </div>

        <div className="space-y-3 py-6">
          <Heading>Triggers</Heading>
          {workflow?.triggers && workflow.triggers.length > 0 ? (
            <div className="space-y-4">
              {workflow.triggers.map((trigger, index) => (
                <TriggerCard key={index} trigger={trigger} index={index} />
              ))}
            </div>
          ) : (
            <div className="text-center py-8 bg-gray-50 rounded-lg">
              <CloudLightningIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No triggers defined</h3>
              <p className="mt-1 text-sm text-gray-500">
                Add triggers to determine when this workflow should be activated.
              </p>
            </div>
          )}
        </div>

        <div className="space-y-3">
          <Heading>Rollouts</Heading>
          <Table.Root variant="surface">

            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Client</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Traffic</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Participants</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>&nbsp;</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {workflow.rollouts?.map((rollout, index) => (
                <RolloutItem key={index} rollout={rollout} workflow={workflow} mutate={mutateWorkflow}/>
              ))}
            </Table.Body>
          </Table.Root>
        </div>

       
      </div>

    </WorkflowLayout>
  );
}

function RolloutItem({rollout, workflow, mutate}) {

  const [data, setData] = React.useState({
    rules: rollout.rules,
    is_active: rollout.is_active,
  });
  const [errors, setErrors] = React.useState(null);
  const closeButton = useRef();

  function handleRollout(evt) {
    evt.preventDefault();
    console.log('save', data);
    apiClient.put(`/convert/workflows/${workflow.id}/rollouts/${rollout.id}`, data)
      .then(() => {
        mutate();
        closeButton.current.click();
      })
      .catch(err => {
        setErrors(err.response.data || [{message: 'An error occurred'}]);
      })
  }

  return (
    <Table.Row>
      <Table.Cell><Link to={`/settings/clients/${rollout.client.id}`}>{rollout.client.name}</Link> </Table.Cell>
      <Table.Cell>
        {rollout.percentage}% of traffic
      </Table.Cell>
      <Table.Cell>
        {rollout.is_active && (
          <span className="text-green-500 font-semibold text-sm"><i className="fa fa-play  text-sm"/> live</span>
        )}
        {!rollout.is_active && (
          <span className="text-amber-500 font-semibold text-sm"><i className="fa fa-pause text-amber-400"/> paused </span>
        )}
      </Table.Cell>
      <Table.Cell>
        {rollout.participations_count}
      </Table.Cell>
      <Table.Cell>
        <Dialog.Root>
          <Dialog.Trigger>
            <Button variant="ghost">Edit</Button>
          </Dialog.Trigger>

          <Dialog.Content maxWidth="450px">
            <Dialog.Title>Edit Rollout</Dialog.Title>
            <Dialog.Description size="2" mb="4">Make changes to this rollout</Dialog.Description>

            <Form.Root onSubmit={handleRollout}>

              <Flex direction="column" gap="3">
                <label>
                  <Text as="div" size="2" mb="1" weight="bold">Active</Text>
                  <Switch checked={data.is_active} onCheckedChange={c => setData({...data, is_active: c})}/>
                  {errors && errors.is_active && <Text size="1" color="red">{errors.is_active[0]}</Text>}
                </label>
                {data?.rules?.map((rule, index) => (
                  <Flex direction="column" gap="3" key={index}>
                    {rule.type === 'percentage' && (
                      <label>
                        <Text as="div" size="2" mb="1" weight="bold">Percentage</Text>
                        <TextInput
                          type="number"
                          min={0}
                          max={100}
                          value={rule.value}
                          name="value"
                          onChange={value => setData(update(data, {rules: {[index]: {value: {$set: Number(value)}}}}))}
                          placeholder="Percentage"
                        />
                      </label>
                    )}
                    {errors && errors.rules && errors.rules[index] && (
                      <Text size="1" color="red">{errors.rules[index].value}</Text>)}
                  </Flex>
                ))}
              </Flex>

              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray" type="button" ref={closeButton}>Cancel</Button>
                </Dialog.Close>

                <Button>Save</Button>
              </Flex>
            </Form.Root>
          </Dialog.Content>
        </Dialog.Root>
      </Table.Cell>
    </Table.Row>
  )
}
