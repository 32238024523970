import {useParams} from "react-router";
import useSWR from "swr";
import {getFetcher} from "~/lib/apiClient";
import Debug from "~/components/Debug";
import PageHeader from "~/components/PageHeader";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import React from "react";

function ScheduleSummary({ schedule }) {
  return (
    <div className="p-6">
      <div>currency: {schedule.currency_code}</div>
      <div>Starts At: {schedule.start_at}</div>
      <div>Ends At: {schedule.end_at}</div>

      <ul>
        {schedule?.plans?.map((plan, i) => (
          <li key={i}>
            <Link to={`/catalog/plans/${plan.id}`}>
              <b>{plan.name}</b> -
              {/*{plan.base_charge.amount_formatted}*/}
              {plan.currency} {plan.renew_interval}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default function CustomerDetailPage() {

  const {customerId} = useParams();

  const { data: customer, error } = useSWR(`/customers/${customerId}`, getFetcher);

  if (!customer) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
      <>
        <PageHeader
          navigation={<Link className="flex items-center" to={`/customers`}><ChevronLeftIcon className="w-6 h-6 text-black" /></Link>}
        >
          <h1 className="text-2xl font-semibold">Customer</h1>
        </PageHeader>

        <div className="flex h-full">
          <div className="w-[400px] border-r bg-white">
            <div className="flex items-center p-4 gap-4">
              {/*<img src="/" className="w-10 h-10 rouned-full" alt=""/>*/}
              <div
                className="relative inline-flex items-center justify-center w-14 h-14 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span className="text-2xl font-semibold text-gray-600 dark:text-gray-300">JL</span>
              </div>
              <h1>{customer.name || customer.email}</h1>
            </div>

            <div className="divide-y">
              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Customer Since</div>
                <div>{customer.created_at}</div>
              </div>

              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Stripe Identifier</div>
                <div>{customer.reference_id}</div>
              </div>

              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Email</div>
                <div>{customer.email}</div>
              </div>

              <div className="flex justify-between items-center px-4 h-12">
                <div className="font-semibold">Phone</div>
                <div>{customer.phone || "-"}</div>
              </div>
            </div>

          </div>
          <div className="flex-grow right">

            {customer.schedule && (
              <ScheduleSummary schedule={customer.schedule}/>
            )}

            <Debug {...customer}/>
          </div>
        </div>
      </>
  )
}
